exports.components = {
  "component---src-components-post-template-tsx-content-file-path-posts-basics-can-adobe-animate-index-mdx": () => import("./../../../src/components/postTemplate.tsx?__contentFilePath=/opt/build/repo/posts/basics/can-adobe-animate/index.mdx" /* webpackChunkName: "component---src-components-post-template-tsx-content-file-path-posts-basics-can-adobe-animate-index-mdx" */),
  "component---src-components-post-template-tsx-content-file-path-posts-basics-change-mmd-heads-index-mdx": () => import("./../../../src/components/postTemplate.tsx?__contentFilePath=/opt/build/repo/posts/basics/change-mmd-heads/index.mdx" /* webpackChunkName: "component---src-components-post-template-tsx-content-file-path-posts-basics-change-mmd-heads-index-mdx" */),
  "component---src-components-post-template-tsx-content-file-path-posts-basics-custom-animations-index-mdx": () => import("./../../../src/components/postTemplate.tsx?__contentFilePath=/opt/build/repo/posts/basics/custom-animations/index.mdx" /* webpackChunkName: "component---src-components-post-template-tsx-content-file-path-posts-basics-custom-animations-index-mdx" */),
  "component---src-components-post-template-tsx-content-file-path-posts-basics-mmd-meaning-index-mdx": () => import("./../../../src/components/postTemplate.tsx?__contentFilePath=/opt/build/repo/posts/basics/MMD-meaning/index.mdx" /* webpackChunkName: "component---src-components-post-template-tsx-content-file-path-posts-basics-mmd-meaning-index-mdx" */),
  "component---src-components-post-template-tsx-content-file-path-posts-basics-sites-to-animate-index-mdx": () => import("./../../../src/components/postTemplate.tsx?__contentFilePath=/opt/build/repo/posts/basics/sites-to-animate/index.mdx" /* webpackChunkName: "component---src-components-post-template-tsx-content-file-path-posts-basics-sites-to-animate-index-mdx" */),
  "component---src-components-post-template-tsx-content-file-path-posts-models-genshin-models-index-mdx": () => import("./../../../src/components/postTemplate.tsx?__contentFilePath=/opt/build/repo/posts/models/genshin-models/index.mdx" /* webpackChunkName: "component---src-components-post-template-tsx-content-file-path-posts-models-genshin-models-index-mdx" */),
  "component---src-components-post-template-tsx-content-file-path-posts-models-grey-raven-models-index-mdx": () => import("./../../../src/components/postTemplate.tsx?__contentFilePath=/opt/build/repo/posts/models/grey-raven-models/index.mdx" /* webpackChunkName: "component---src-components-post-template-tsx-content-file-path-posts-models-grey-raven-models-index-mdx" */),
  "component---src-components-post-template-tsx-content-file-path-posts-models-honkai-models-index-mdx": () => import("./../../../src/components/postTemplate.tsx?__contentFilePath=/opt/build/repo/posts/models/honkai-models/index.mdx" /* webpackChunkName: "component---src-components-post-template-tsx-content-file-path-posts-models-honkai-models-index-mdx" */),
  "component---src-components-post-template-tsx-content-file-path-posts-models-vtuber-models-index-mdx": () => import("./../../../src/components/postTemplate.tsx?__contentFilePath=/opt/build/repo/posts/models/vtuber-models/index.mdx" /* webpackChunkName: "component---src-components-post-template-tsx-content-file-path-posts-models-vtuber-models-index-mdx" */),
  "component---src-components-post-template-tsx-content-file-path-posts-models-wuthering-models-index-mdx": () => import("./../../../src/components/postTemplate.tsx?__contentFilePath=/opt/build/repo/posts/models/wuthering-models/index.mdx" /* webpackChunkName: "component---src-components-post-template-tsx-content-file-path-posts-models-wuthering-models-index-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-basics-tsx": () => import("./../../../src/pages/basics.tsx" /* webpackChunkName: "component---src-pages-basics-tsx" */),
  "component---src-pages-featured-tsx": () => import("./../../../src/pages/featured.tsx" /* webpackChunkName: "component---src-pages-featured-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-models-tsx": () => import("./../../../src/pages/models.tsx" /* webpackChunkName: "component---src-pages-models-tsx" */),
  "component---src-pages-r-18-tsx": () => import("./../../../src/pages/r18.tsx" /* webpackChunkName: "component---src-pages-r-18-tsx" */)
}

